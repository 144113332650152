export const state = () => ({
	inputNumber: 0,
	cardsPerRow: 3,
	ticketLayoutStyle: 'grid'
});

export const getters = {
	getCardsPerRow: state => state.cardsPerRow,
	getInputNumber: state => state.inputNumber,
	getTicketLayoutStyle: state => state.ticketLayoutStyle
};

export const mutations = {
	setInputNumber(state, payload) {
		state.inputNumber = payload;
	},

	setTicketLayoutStyle(state, payload) {
		if (typeof payload !== 'string') return;
		state.ticketLayoutStyle = payload;
	},

	setCardsPerRow(state, payload) {
		if (isNaN(payload)) return;
		state.cardsPerRow = payload;
	}
};
