/**
 * Compares the keys of multiple objects and returns an array of keys that are missing
 * in at least one of the objects.
 *
 * @param {...Object} objects - The objects to compare.
 * @returns {string[]} An array of strings representing the missing keys.
 */
export function compareObjectsKeys(...objects) {
	const allKeys = objects.flatMap(Object.keys);
	const keyCounts = allKeys.reduce((acc, key) => {
		acc[key] = (acc[key] || 0) + 1;

		return acc;
	}, {});

	const missingKeys = Object.keys(keyCounts).filter(key => keyCounts[key] < objects.length);
	return missingKeys;
}

export default compareObjectsKeys;
