<template>
	<transition>
		<div
			class="checkout"
			v-if="getShowCheckout && getShopCart && getShopCart.status !== 'confirmed'"
			ref="checkout"
			:class="{ 'checkout--has-counter': showCounter }"
			:style="heightStyle"
		>
			<div class="checkout__header">
				<Grid>
					<Column>
						<div class="checkout__header-wrapper">
							<div>
								<h1>{{ cartTitle }}</h1>

								<span
									class="checkout__header-preview"
									v-if="shopConfig.previewMode"
									v-html="shopConfig.previewModusCopy.body"
								></span>

								<p class="checkout__header-body" v-else-if="!shopConfig.preregistration">
									{{ cartBody }}
								</p>
							</div>

							<button
								class="checkout__close"
								@click="closeCart"
								v-if="
									shopConfig.preregistration ||
									(getShopCart.status !== 'confirmed' && getShopCart.status !== 'in_payment')
								"
							>
								<Close />
							</button>
						</div>
					</Column>
				</Grid>
			</div>

			<Grid class="checkout__inner">
				<Column>
					<Basket
						v-if="
							!showCheckoutForm &&
							getShopCart.status !== 'confirmed' &&
							getShopCart.status != 'in_payment'
						"
					/>

					<CheckoutForm v-if="showCheckoutForm && getShopCart.status === 'shopping'" />

					<!--<PaymentMethods v-if="getPaymentProvider != 'adyen' && getShopCart.status === 'confirmed' || getShopCart.status === 'in_payment' " />-->
				</Column>
			</Grid>
		</div>
	</transition>
</template>

<script>
import CheckoutForm from '@/components/checkout/CheckoutForm';
import PaymentMethods from '@/components/checkout/PaymentMethods';
import Basket from '@/components/checkout/Basket';

export default {
	name: 'CheckOut',

	components: {
		CheckoutForm,
		PaymentMethods,
		Basket
	},

	watch: {
		showCheckoutForm(val) {
			this.$refs.checkout.scrollTo(0, 0);
		}
	},

	computed: {
		getPaymentProvider() {
			return this.$store.getters['shopconfig/getPaymentProvider'];
		},
		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		cartTitle() {
			if (this.shopConfig.preregistration) {
				return this.$t('wish_list.checkout.title');
			} else if (this.shopConfig.previewMode) {
				return this.shopConfig.previewModusCopy.title;
			} else {
				return this.$t('checkout.title');
			}
		},

		cartBody() {
			if (this.showCheckoutForm && this.getShopCart.status === 'shopping') {
				return this.$t('checkout.lead_complete');
			} else if (this.shopConfig.previewMode) {
				return this.$t('checkout.lead_upgrade');
			} else return this.$t('checkout.lead');
		},

		showCounter() {
			return this.$store.getters.getShowCounter;
		},

		showCheckoutForm() {
			return this.$store.getters.getShowCheckoutForm;
		},

		getShopCart() {
			return this.$store.getters['shopconfig/getCart'];
		},

		getShowCheckout() {
			return this.$store.getters.getShowCheckout;
		},

		productsInBasket() {
			return this.$store.getters['shopapi/getMappedProductsInBasket'];
		},

		heightStyle() {
			return `height:${this.viewport.height}px`;
		}
	},

	watch: {
		productsInBasket(val) {
			const products = Object.keys(val);
			if (products.length === 0 || (products.length === 1 && products[0] == 'insurance')) {
				this.closeCart();
			}
		}
	},

	methods: {
		closeCart() {
			this.getShopCart &&
				this.getShopCart.status !== 'confirmed' &&
				this.$store.commit('setShowCheckoutForm', false);
			this.$store.commit('setShowCheckout', false);
		}
	}
};
</script>

<style lang="scss">
$component: 'checkout';

.#{$component} {
	display: block;

	position: fixed;
	z-index: $layer-checkout;

	width: 100vw;
	// height: 100vh;

	overflow-y: scroll;
	overflow-x: hidden;

	background: var(--content-background);

	top: 0;
	left: 0;

	&.v-enter,
	&.v-leave-to {
		opacity: 0;

		.#{$component}__header,
		.#{$component}__inner {
			transform: translateY(20%);
		}
	}

	&.v-enter-active {
		transition: opacity 0.4s $ease-in-out-sine;

		.#{$component} {
			&__header,
			&__inner {
				transition: transform 0.5s $ease-out-cubic;
			}
		}
	}

	&.v-leave-active {
		transition: opacity 0.4s $ease-in-out-sine;
		// transition-delay: 3s;

		.#{$component} {
			&__header,
			&__inner {
				transition: transform 0.5s $ease-in-cubic;
				// transition-delay: 3s;
			}

			&__header > .grid-container {
				left: 0;
			}
		}
	}

	&--has-counter {
		.#{$component} {
			&__header {
				height: $checkout-header-height + $counter-height;
			}

			&__header-wrapper,
			&__inner {
				padding-top: $counter-height;
			}
		}
	}

	&__inner {
		z-index: 4;
		position: relative;
		min-height: 100%;
	}

	> .grid-container {
		background-color: rgba(white, 0.1);
	}

	&__header {
		z-index: 5;
		position: fixed;

		display: flex;
		flex-direction: column;
		justify-content: center;

		width: 100%;
		min-height: $checkout-header-height;

		background-color: var(--content-background);

		> .grid-container {
			flex: auto;
			position: relative;
			left: -2px;

			height: 100%;
			padding-top: $counter-height;

			background-color: rgba(white, 0.1);
		}
	}

	&__header-wrapper {
		position: relative;

		display: flex;
		justify-content: space-between;
		align-items: center;

		// width: calc(100% - #{$global-spacing * 4 + 5px}); // 5px scrollbar compensation
		// max-width: calc(1180px - 80px);
		// left: -5px;
		height: 100%;

		margin: 0 auto;
		// padding-left: 45px;
		// padding-right: 45px;
	}

	&__header-preview {
		color: $color-red;
		font-size: 1rem;
	}

	&__header-body {
		padding-right: $global-spacing;
		font-size: 14px;
	}

	&__close {
		position: relative;
		right: 0;
	}

	@include sm {
		&--has-counter {
			.#{$component} {
				&__header {
					height: $checkout-header-height--mob + $counter-height;
				}
			}
		}

		&__header {
			min-height: $checkout-header-height--mob;

			> .grid-container {
				padding-bottom: 5px;
			}
		}
	}
}
</style>
