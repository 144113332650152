/**
 * @function isShopifyProduct
 * @description Returns true if the given string starts with the string 'shopify_'.
 * @param {string} str - The string to check.
 * @returns {boolean} Whether the string starts with 'shopify_'.
 */
export function isShopifyProduct(str) {
	return typeof str === 'string' && str.startsWith('shopify_');
}

export default isShopifyProduct;
