// Try to keep the keys in this translation file in ascending alphabetical order in order to quickly find translations
export default {
	alert: {
		complete_order_in_time: 'Asegúrese de completar su pedido antes de que se agote el temporizador',
		complete_payment_in_time: 'Asegúrese de completar su pago antes de que se agote el temporizador',
		no_stock: 'Este artículo está agotado.Puede seleccionar una alternativa.',
		no_refund_choice: 'Todavía no ha tomado una decisión con respecto a la protección de la reserva',
		no_checkout: 'Todavía no puedes pagar porque:',
		sold_out: 'Este artículo está agotado.Puede seleccionar una alternativa.'
	},
	checkout: {
		calculate: 'Calcular los costos de envío',
		entertainment_tax: {
			title: 'Impuesto de entretenimiento local',
			lead: 'La ciudad de Amsterdam implementó un impuesto de entretenimiento.Si utiliza el transporte o el entretenimiento como cliente que paga, debe pagar un impuesto sobre el entretenimiento (Vermakelijkhedenributie, VMR).A partir del 1 de enero de 2023, el Event Entertainment Impuesto (VMR) se aplicará a eventos y festivales pagados organizados en espacios públicos.Los visitantes pagan 1.50 € cada día del festival al organizador del evento.'
		},
		included_vat: 'Incl.IVA y ServiceCosts ',
		lead: 'Verifique el contenido de su carrito antes de completar su pedido',
		lead_complete: 'Complete sus datos personales',
		lead_upgrade: 'A continuación encontrará una descripción general de su pedido',
		payment_method: {
			select: 'Seleccione un método de pago',
			method: 'Método de pago',
			zero: 'Seleccione No se requiere pago para completar su pedido.',
			discount_next_step: 'Su descuento se deduce del monto total en el siguiente paso'
		},
		payment_status: {
			error: {
				title: 'El pago fallido',
				lead: '',
				message:
					'Desafortunadamente, algo salió mal con su pago y su pedido no fue exitoso.Es posible volver a ingresar a la tienda, pero tenga en cuenta que se colocará en la parte posterior de la lista de espera.'
			},
			failed: {
				title: 'El pago fallido',
				lead: '',
				message:
					'Desafortunadamente, algo salió mal con su pago y su pedido no fue exitoso.Es posible volver a ingresar a la tienda, pero tenga en cuenta que se colocará en la parte posterior de la lista de espera.'
			},
			pending: {
				title: 'El pago está pendiente',
				lead: '',
				message:
					"<h3> Gracias por su pedido.Todavía estamos esperando la confirmación final de su pago por parte del proveedor de pagos. </h3> <br> <br> <h3> No se preocupe, normalmente obtenemos la confirmación en un par de minutos. </h3> <Br> <br> <p> Esto sucederá a continuación: </p> <ul> <li> Su pedido y pago se procesarán </li> <li> Recibirá un correo electrónico de confirmación </li> <li> Recibirá un código de seguimiento tan pronto como enviemos su pedido (si corresponde) </li> </ul> <br> <p> Si el dinero se deduce de su cuenta bancaria y no obtiene una confirmación dentro de 2Horas, comuníquese con <a href='https://customerservice.paylogic.com/' target='_blank'> payLogic </a>.También puede comunicarse con ellos para cualquier otra pregunta sobre su pedido. </p>"
			},
			canceled: {
				title: 'Pago cancelado',
				lead: '',
				message:
					'Desafortunadamente, algo salió mal con su pago y su pedido no fue exitoso.Es posible volver a ingresar a la tienda, pero tenga en cuenta que se colocará en la parte posterior de la lista de espera.'
			},
			completed: {
				title: 'Gracias',
				lead: '',
				message:
					'Gracias por su pedido.Actualmente estamos procesando su pedido y pago.¡Esta no es una confirmación!Su pedido se confirmará después de haber recibido un correo electrónico de confirmación.Si no recibe un correo electrónico de confirmación dentro de las 2 horas, consulte su cuenta bancaria o tarjeta de crédito para ver si el pago fue exitoso.'
			},
			paymentProvider: {
				adyen: {
					message:
						"Si tiene alguna pregunta sobre su pago u pedido, comuníquese con <a Target='_Blank' href='https://www.justbleep.it/'> Bleep Customer Support </a>."
				},
				paylogic: {
					message:
						"Si tiene alguna pregunta sobre su pago u pedido, comuníquese con <a Target='_blank' href='https://customerservice.paylogic.com/'> Atención al cliente de Paylogic </a>."
				}
			}
		},
		insurance: {
			addon: 'Agregue la protección de reembolso a sus nuevos complementos para solo',
			add: 'Agregar protección de reserva para todos sus boletos para justo',
			extend: 'Extiende la protección de restitución para su actualización para',
			xcover: {
				uri: '<a href="https://www.xcover.com/en/pds/booking-refund-protection-eu" target="_blank"> encontrar detalles de cobertura completos </a>',
				body: 'Está cubierto si no puede asistir por razones médicas (incluido Covid-19), debido a los servicios de emergencia que requieren que esté en el hogar o si las autoridades emiten una advertencia de viaje.La protección de reembolso es llevada a cabo por nuestro socio Xcover.'
			}
		},
		orderfee: 'Tarifa de pedido',
		service_fee: 'Tarifa de servicio',
		subtotal: 'Total parcial',
		title: 'Tu carro',
		total: 'total',
		tourist_tax: {
			title: 'Impuesto turístico',
			lead: 'Este es un impuesto local impuesto por algunos municipios y ciudades, que se utiliza para financiar y mantener instalaciones locales.La cantidad variará según el municipio y, a menudo, es una pequeña cantidad por persona por noche.En este caso será € por persona por noche'
		},
		voucher: {
			add: 'Agregar código de cupón',
			invalid: 'Este código de cupón no es válido',
			used: 'Este código de cupón ya se ha utilizado',
			redeem: 'Redime de código de cupón',
			success: 'Voucher redimido con éxito!'
		}
	},
	error: {
		form_covid_not_accepted: 'Todavía no ha estado de acuerdo con las condiciones de reembolso Covid',
		form_has_errors: 'Arregle los errores en el formulario para completar su pedido',
		form_incomplete: 'Complete todos los campos requeridos *',
		form_no_country_selected: 'Todavía no ha seleccionado un país para calcular los costos de envío',
		form_no_insurance_choice: 'Indique si desea agregar una protección de reserva para su pedido',
		form_terms_not_accepted: 'Acepte los términos y condiciones para completar su pedido',
		generic:
			'Intentar otra vez.Actualice esta página y, si es necesario, únase a la cola para acceder a la tienda de entradas nuevamente.',
		invalid_request: 'Se ha realizado una solicitud no válida.Por favor intente de nuevo.',
		no_config_found: 'Falta una configuración de OSS.Póngase en contacto con el soporte.',
		order_lookup_failed:
			'Al intentar encontrar su pedido, se produjo un error.Algo salió mal de nuestro lado.Pruébalo de nuevo más tarde',
		order_lookup_invalid: 'Su número de pedido parece inválido, copie el número correcto del correo de PayLogic.',
		order_lookup_no_order:
			'No podemos encontrar un pedido con ese número de pedido. Copia el número de pedido correcto del correo de PayLogic.',
		page_not_found: 'Página no encontrada',
		shipping_unavailable: 'El envío no está disponible actualmente para su ubicación',
		undefined: 'Se ha producido un error desconocido',
		code: {
			0: '-Modal-cerrado--',
			1000: {
				title: 'Ups ...',
				message: 'Algo salió mal.Por favor intente de nuevo.'
			},
			2000: {
				title: 'Bienvenido a la {marca} tienda',
				message: 'Iniciar sesión / crear una cuenta para comenzar.',
				label_cancel: 'CONTINUAR'
			},
			2001: {
				title: 'Bienvenido a la {marca} tienda',
				message: 'Iniciar sesión / crear una cuenta para comenzar.',
				label_cancel: 'CONTINUAR'
			},
			2002: {
				title: 'Bienvenido a la {marca} tienda',
				message: 'Iniciar sesión / crear una cuenta para comenzar.',
				label_cancel: 'CONTINUAR'
			},
			3000: {
				title: 'Error de registro',
				message:
					'No hay registro vinculado a esta cuenta {Brand}.Póngase en contacto con info@q-dance.com para obtener más información.'
			},
			3001: {
				title: 'Error de registro',
				message: 'Ya se ha realizado un pedido con esta cuenta'
			},
			3002: {
				title: 'Error de registro',
				message: 'El registro está cerrado.'
			},
			3003: {
				title: 'No permitida',
				message: 'La tienda aún no está abierta para ti.Consulte su intervalo de tiempo y vuelva a intentarlo.'
			},
			3005: {
				title: 'OOPS ... YA ESTÁS DEDICADA',
				message: 'Solo puede comprar una membresía por cuenta.'
			},
			4000: {
				title: 'La tienda está cerrada'
			},
			4001: {
				title: 'Gracias',
				message: '¡Tu lista de deseos ha sido guardada con éxito!'
			},
			4002: {
				title: 'Error de actualización',
				message:
					'Pedido ya procesado o no encontrado.Si cree que este es un error, haga clic en el enlace de correo electrónico nuevamente.'
			},
			4003: {
				title: 'Error de actualización',
				message:
					'Pedido ya procesado o no encontrado.Si cree que este es un error, haga clic en el enlace de correo electrónico nuevamente.'
			},
			4004: {
				title: "Time's up",
				message:
					'Debido al tráfico pesado, solo puede navegar por un tiempo limitado.Puede volver a entrar en la tienda haciendo clic en el enlace en su correo electrónico.',
				label_cancel: false
			},
			4005: {
				title: 'Sin ID de pedido válido',
				message:
					'Pedido ya procesado o no encontrado.Si cree que este es un error, haga clic en el enlace de correo electrónico nuevamente.'
			},
			4006: {
				title: 'No hay ID de acceso válido',
				message:
					'Necesita una ID de acceso válida para ingresar a la tienda.Intente hacer clic en el enlace en el correo electrónico nuevamente.'
			},
			5000: {
				title: 'Producto agotado'
			},
			5001: {
				title: 'Producto no encontrado'
			},
			5002: {
				title: 'No se encontraron paquetes de viajes',
				message: 'Por favor, haga una selección diferente'
			},
			5003: {
				title: 'Envío no disponible',
				message:
					'Los productos seleccionados se han eliminado/se han eliminado automáticamente de su carrito.Desafortunadamente, no es posible enviar mercancías al país que haya seleccionado. <br> Para cualquier pregunta, contáctenos en <a href="mailto:merchandise@q-dance.com"> merchandise@q-dance.com </a>'
			},
			6000: {
				title: 'Cambiar registro',
				message:
					'Tenga en cuenta que necesita volver a seleccionar su tipo de registro (solo paquetes de viaje y estadía o boleto) para ingresar a la venta de miembros.Complete su registro nuevamente en el siguiente paso.',
				label_cancel: 'Cancelar'
			},
			6001: {
				title: 'Eliminar el elemento',
				message: '¿Estás segura de que quieres eliminar este artículo?',
				label_cancel: 'Cancelar'
			},
			6002: {
				title: "Time's up",
				message:
					"It looks like it took too much time to complete your order. Don't worry, you can try again by clicking the button below. Make sure to complete your order within 7 minutes though!",
				label_cancel: false,
				label_confirm: 'Intentar otra vez'
			},
			6003: {
				title: 'No hay boleto (s) seleccionado',
				message:
					'Su pedido contiene un alojamiento sin boletos de entrada.Tenga en cuenta que la entrada a {FestivalID} solo es posible con un boleto de entrada.¿Estás seguro de que quieres continuar con tu compra?',
				label_cancel: false,
				label_confirm: 'Continuar'
			},
			6004: {
				title: 'Bienvenido a la {marca} tienda',
				message: 'Iniciar sesión / crear una cuenta para comenzar.',
				label_cancel: false,
				label_confirm: 'Acceso'
			},
			6005: {
				title: 'Venta de miembro dediqated',
				message: '<p> Necesita una membresía dedicada para ingresar a esta tienda. </p>',
				label_cancel: false,
				label_confirm: 'Comercio'
			},
			6010: {
				title: 'Estas segura',
				message: '<p> ¿Estás segura de que deseas cancelar tu pedido? </p>',
				label_cancel: 'no',
				label_confirm: 'Sí'
			},
			7000: {
				title: 'Atención',
				message:
					'La venta de miembros de Travel & Stay comienza el 29 de febrero.Si desea obtener Acces, deberá cambiar su registro para viajar y quedarse.Todos los paquetes restantes también saldrán a la venta durante la venta de miembros del boleto el 7 de marzo.'
			},
			8000: {
				title: 'Atención',
				message: ''
			},
			8001: {
				title: 'Atención',
				message: '',
				label_cancel: false
			},
			8002: {
				title: 'Ha ocurrido un error',
				message: '',
				label_cancel: false
			},
			8003: {
				title: '',
				message: '',
				label_cancel: 'CONTINUAR'
			}
		}
	},
	form: {
		address_information: 'Información de dirección',
		birthday: 'Fecha de nacimiento',
		city: 'Ciudad',
		country: {
			label: 'País'
		},
		countrycode: {
			label: 'Código del país'
		},
		email: 'Dirección de correo electrónico',
		extension: 'Número sufijo',
		filter: 'Elija un filtro',
		filter_explain: 'Encuentre lo que necesita filtrando una de las categorías a continuación',
		firstname: 'Nombre de pila',
		gender: {
			female: 'Femenina',
			label: 'Género',
			male: 'Hombre',
			other: 'Otra'
		},
		lastname: 'Apellido',
		personal_information: 'Información personal',
		phonenumber: 'Número de teléfono',
		postalcode: 'Código Postal',
		select: 'Haz una selección',
		shipping_information: 'Información de envío',
		state: 'Estado',
		streetname: 'Nombre de la calle',
		streetnumber: 'Número de calle'
	},
	label: {
		back: 'Atrás',
		cancel: 'Cancelar',
		cart: {
			add: 'Agregar',
			add_to_cart: 'Añadir a la cesta',
			add_to_wishlist: 'Agregar a la lista de deseos',
			cart: 'Carro',
			complete_payment: 'Pago completo',
			complete_order: 'Orden de acabado',
			continue_shopping: 'Continuar comprando',
			order: 'Listo para ordenar',
			remove: 'Eliminar',
			remove_from_cart: 'Retirar del carrito',
			remove_from_wishlist: 'Eliminar de la lista de deseos',
			replace_item: 'Reemplazar',
			pay: 'Pagar',
			save: 'Guardar preinscripción',
			wishlist: 'Lista de deseos'
		},
		complete_your_experience: 'Completa tu experiencia',
		find_order: 'Encuentra mi pedido',
		extras: 'extras',
		go_to_checkout: 'Ve a pagar',
		go_to_wishlist: 'Ir a la lista de deseos',
		merchandise: 'mercancías',
		more_info: 'Más información',
		navigation: {
			back: 'Volver',
			checkout: 'Ve a pagar',
			next: 'Próxima',
			overview: 'Descripción general',
			previous: 'Anterior',
			wishlist: 'Ir a la lista de deseos'
		},
		no: 'no',
		reset: 'Reiniciar',
		return_to_homepage: 'Regreso a la página de inicio',
		shop: {
			members_sale: 'Tienda de boletos',
			preregistration_sale: 'Preinscripción',
			regular_sale: 'Tienda de boletos',
			travel_sale: 'Tienda de viajes',
			wishlist: 'Tienda de lista de deseos'
		},
		ticket: 'boletas',
		transportation: 'transporte',
		travel: 'viajar',
		try_again: 'Intentar otra vez',
		yes: 'Sí'
	},
	loading: 'Cargando...',
	loading_one_moment: 'Un momento por favor ...',
	modal: {
		save_time: {
			body: 'Inicie sesión ahora y ahorre tiempo durante el pago',
			cta: 'Iniciar sesión o registrarse',
			title: 'Ahorre tiempo'
		}
	},
	page: {
		extras: {
			lead: 'Seleccione sus productos adicionales, como tarjetas de estacionamiento, casilleros y otras experiencias',
			title: 'Extras y otras experiencias'
		},
		home: {
			lead: 'Bienvenido, por favor elija un evento para comenzar',
			title: '{marca} una tienda de paradas'
		},
		merchandise: {
			lead: 'Seleccione su mercancía',
			one_size: 'La talla única se ajusta a todos',
			title: 'Mercancías'
		},
		shop: {
			experience_prebuilder: {
				form: ['I am interested in', 'I will travel by', 'How many persons?'],
				lead: "Haga clic en 'solo boletos' para ordenar boletos.¿Vas a buscar la experiencia todo en uno que incluye alojamiento?Haga clic en 'Paquetes de viaje y estancia' para todos los paquetes de viaje y alojamiento.",
				title: 'Crea tu experiencia'
			},
			experience_builder: {
				choose: 'Elige tu',
				select: 'Seleccione su',
				extras: {
					label: 'Extras y otras experiencias',
					remove: 'Eliminar',
					update: 'Cambiar/agregar'
				},
				lead: '¿Qué productos le gustaría agregar a su carrito?',
				merchandise: {
					label: 'Mercancías',
					remove: 'Eliminar',
					update: 'Cambiar/agregar'
				},
				ticket: {
					label: '{Evento} entradas',
					remove: 'Eliminar',
					update: 'Cambiar/agregar'
				},
				title: 'Tu carro',
				transportation: {
					label: 'Transporte de autobuses organizado',
					remove: 'Eliminar',
					update: 'Cambiar/agregar'
				},
				travel: {
					label: 'Paquetes de viaje y estadía',
					remove: 'Eliminar',
					update: 'Reserva de actualización'
				}
			},
			lead: 'Todo lo que necesitas en una sola tienda',
			title: 'Comercio'
		},
		shop_addon: {
			experience_prebuilder: {
				lead: 'Ahora puede agregar productos como casilleros, boletos de estacionamiento, paquetes de viajes y alojamiento y mercancías a su cesta de compras',
				title: "{event} extra's shop"
			},
			lead: 'Una descripción general de sus pedidos anteriores',
			loading: {
				title: 'Comprobando sus pedidos anteriores ...',
				lead: 'Estamos buscando sus pedidos anteriores.No actualice esta página, cargar sus pedidos anteriores puede tomar hasta un minuto.'
			},
			no_order: {
				lead: 'Ingrese el número de pedido que ha recibido en su correo electrónico (el número de pedido que recibió del Booker principal).Asegúrese de iniciar sesión con la misma cuenta que se usó para realizar su pedido.',
				title: 'No se han encontrado órdenes anteriores.'
			},
			title: 'Su (s) producto (s) comprado (s).'
		},
		old: {
			package: {
				addons: 'Tus extensiones elegidas en la canasta de compras',
				summary: 'Resumen de su paquete elegido'
			},
			changedorder:
				'Tenga en cuenta que este pedido puede no mostrar los datos de reserva más recientes, porque hemos cambiado su pedido manualmente a su solicitud.No se preocupe, tenemos sus datos de reserva más recientes en nuestro sistema de reserva y en esta tienda encontrará todas las extensiones posibles que sean adecuadas para su paquete de transporte y alojamiento.',
			referenceLabel: 'Tu número de pedido'
		},
		tickets: {
			lead: 'Lorem muy zanahorias, desarrollador de estudiantes de tomate, pero estaré en un iniquod y dolor para trabajar y dolor.',
			remove: 'Eliminar boletos',
			select: 'Seleccione sus boletos',
			sold_out: 'Agotado',
			sold_out_combo:
				'¡Las entradas están agotadas!Una cantidad limitada aún puede estar disponible en combinación con {enlace} ',
			title: 'Boletas',
			upsell_accommodations: 'Los paquetes de alojamiento están disponibles para que pueda agregar a su pedido',
			upsell_extras: 'Están disponibles productos adicionales para que pueda agregar a su pedido',
			upsell_travel: 'Los paquetes de viaje están disponibles para que usted agregue a su pedido.'
		},
		transportation: {
			lead: 'Seleccione el transporte de autobuses organizado',
			title: 'Transporte de autobuses organizado'
		},
		travel: {
			lead: 'Seleccione sus paquetes de viaje y permanencia',
			title: 'Paquetes de viajes y estadías'
		}
	},
	remaining: 'restante',
	time: {
		hour: 'Hora |horas',
		hour_abbr: 'H',
		minute: 'minuto |minutos',
		minute_abbr: 'Min',
		second: 'Segundo |artículos de segunda clase',
		second_abbr: 's'
	},
	wish_list: {
		checkout: {
			title: 'Tu lista de deseos'
		},
		experience_builder: {
			title_wishlist: 'Tu lista de deseos',
			lead_wishlist:
				'You can now add products such as tickets, travel and accommodation packages, merchandise and extras to your wish list.Your wish list can be stored in your account.'
		},
		title: 'Tu lista de deseos'
	}
};
