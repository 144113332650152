<template>
	<div class="input-number-field">
		<div
			class="input-number-field__input-wrapper"
			:class="{
				'input-number-field__input-wrapper--disabled': inputIsDisabled,
				'input-number-field__input-wrapper--dark': isDark,
				'input-number-field__input-wrapper--small': isSmall
			}"
		>
			<!-- <div v-html="generateDataList()" /> -->
			<button
				class="input-number-field__icon"
				@click="decrement"
				:disabled="inputIsZero"
				:title="`Remove ${stepSize} tickets`"
			>
				<span>-</span>
			</button>
			<input
				class="input-number-field__input"
				type="number"
				:ref="idRef"
				:min="minValue"
				:max="maxValue"
				:step="stepSize"
				:disabled="inputIsDisabled"
				:value="value"
				@input="onInput"
			/>
			<button
				class="input-number-field__icon"
				@click="increment"
				:disabled="inputIsDisabled || maxReached"
				:title="`Add ${stepSize} tickets`"
			>
				<span>+</span>
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'InputNumberField',
	data() {
		return {
			idRef: 'input-' + Math.random().toString(16).substring(7)
		};
	},

	props: {
		stepSize: {
			type: Number,
			default: 1
		},
		value: {
			type: Number,
			default: 0
		},

		minValue: {
			type: Number,
			default: 0
		},

		maxValue: {
			type: Number,
			default: 9
		},

		stock: {
			type: Number,
			default: 0
		},

		isDisabled: {
			type: Boolean,
			default: false
		},

		isDark: {
			type: Boolean,
			default: false
		},
		isSmall: {
			type: Boolean,
			default: false
		},
		type: {
			type: String,
			default: null
		}
	},

	computed: {
		inputIsDisabled() {
			return this.isDisabled || this.stock < 1;
		},

		inputIsZero() {
			return this.value === 0;
		},

		maxAmount() {
			return Math.min(this.stock, this.maxValue);
		},
		maxReached() {
			return !this.inputIsDisabled && this.value >= this.maxAmount;
		}
	},

	methods: {
		onInput(e) {
			const el = this.$refs[this.idRef];
			const sanitizedValue = Math.abs(el.value);

			el.value = sanitizedValue;

			// Check validity
			const valid = el.validity.valid;

			if (!valid) el.classList.add('error');
			else {
				el.classList.remove('error');
				this.$emit('input', Math.max(this.minValue, Math.min(sanitizedValue, this.maxAmount)));
			}
		},

		decrement() {
			if (this.type === 'ticket-stepper' && this.minValue && this.value <= this.minValue) {
				this.$emit('input', this.value - this.minValue);
				return;
			}

			const nextAmount = Math.max(this.value - 1 * this.stepSize, this.minValue);

			if (nextAmount !== this.value) {
				this.$emit('input', nextAmount);
			}
		},

		increment() {
			// const nextAmount = Math.min(this.value + 1, this.maxAmount);

			if (this.type === 'ticket-stepper' && this.minValue && this.value < this.minValue) {
				this.$emit('input', this.value + this.minValue);
				return;
			}

			// if (nextAmount !== this.value && !this.inputIsDisabled) {
			if (!this.maxReached && !this.inputIsDisabled) {
				this.$emit('input', this.value + 1 * this.stepSize);
			}
		},
		generateDataList() {
			if (this.type === 'ticket-stepper' && this.maxValue >= 10) {
				// Calculate the maximum number of steps
				const maxSteps = Math.floor((this.maxValue - this.minValue) / this.stepSize) + 1;
				console.log('maxSteps', maxSteps);

				// Limit to 10 steps
				const numSteps = Math.min(maxSteps, 10);
				console.log('numSteps', numSteps);

				const steps = (this.maxValue - this.minValue) / numSteps;

				// Generate the numbers

				let numbers = [];
				for (let t = this.minValue; t < this.maxValue; t += steps) {
					numbers.push(t * this.stepSize);
				}

				// const numbers = Array.from({ length: numSteps }, (_, i) => t + i * this.stepSize);
				console.log('numbers', numbers);
				let html = `<datalist id="ticket-${this.stepSize}-options">`;
				numbers.forEach(number => {
					html += `<option value="${number}">`;
				});
				html += `</datalist>`;

				return html;
			}
			return '';
		}
	},

	mounted() {}
};
</script>

<style lang="scss">
$component: 'input-number-field';

.#{$component} {
	.arrow {
		display: block;
		width: 0px;
		height: 0px;
		border: 15px solid transparent;

		&-up {
			margin-top: 2px;
			border-bottom-color: black;
			border-top-width: 0px;
		}
		&-down {
			margin-top: 4px;
			border-top-color: black;
			border-bottom-width: 0px;
		}
	}

	&__input-wrapper {
		position: relative;

		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 40px;
		color: $color-white;
		border-radius: 20px;
		gap: 1px;

		&--dark {
			.#{$component}__input {
				background-color: rgba($color-grey, 0.1);
				color: $color-white;
			}
		}

		&--small {
			height: 50px;
		}
	}

	&__input {
		display: block;
		height: 100%;
		font-size: 20px;
		color: $color-black;
		text-align: center;
		background-color: #d8d8d8;
		display: block;
		width: 100%;
		height: 100%;
		font-size: 20px;
		padding: 0 20px;
		color: $color-black;
		text-align: center;
		background-color: #d8d8d8;

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		&.error {
			border: 1px solid red;
		}
	}

	button {
		position: relative;
		flex-shrink: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 40px;
		font-family: $font-bold;
		font-size: 24px;
		line-height: 1;
		text-shadow: var(--primary-body-color-dark);

		background-color: rgba(var(--button-alternative-background-color), 1);
		color: var(--button-alternative-text-color);

		span {
			margin-top: -4px;
		}

		&:disabled,
		&--disabled {
			filter: brightness(30%);
			&:hover {
				cursor: not-allowed;
			}
		}

		&:not(:disabled):not(&--disabled) {
			html.no-touchevents & {
				transition: opacity 0.3s $ease-out-cubic;

				&:hover {
					opacity: 0.6;
				}
			}
		}
	}
}
</style>
