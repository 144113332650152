<template>
	<div class="basket-memberships">
		<BasketItem
			v-for="(product, index) in basketItemsData"
			:data="product"
			:key="`product${index}`"
			@remove="onRemove"
		/>
	</div>
</template>

<script>
import BasketItem from './BasketItem';

export default {
	name: 'BasketMemberships',

	components: { BasketItem },

	computed: {
		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		shopCart() {
			return this.$store.getters['shopconfig/getCart'];
		},

		membershipsProductData() {
			return this.$store.getters['shopapi/getProducts'] && this.$store.getters['shopapi/getProducts'].membership;
		},

		membershipsInBasket() {
			return this.$store.getters['shopapi/getMappedProductsInBasket'].membership || [];
		},

		check() {
			return this.membershipsProductData.find(
				membership => membership.id === this.membershipsInBasket[0].type.id
			);
		},

		basketItemsData() {
			return this.membershipsInBasket.map(product => {
				const metaData = this.membershipsProductData.find(membership => membership.id === product.type.id);
				const isMonthly = metaData && metaData.meta.membershipType && metaData.meta.membershipType === 'Month';
				const termsKey = isMonthly ? 'membership_monthly' : 'membership';

				return {
					id: product.type.id,
					title: product.type.name || '',
					description: `1-${isMonthly ? 'month' : 'year'} subscription`,
					price: product.type.price,
					numberOfProducts: product.numproducts,
					image: product.type.meta.image,
					icon: 'IconBuilderMembership',
					terms: this.$t(`${termsKey}.disclaimerCheckout`),
					hideButtons: true,
					showRemoveButton: true,
					type: 'membership',
					data: product
				};
			});
		}
	},

	methods: {
		getTerms(productId) {
			const metaData = this.membershipsProductData.find(membership => membership.id === productId);
			const isMonthly = metaData && metaData.meta.membershipType && metaData.meta.membershipType === 'Month';
			const termsKey = isMonthly ? 'membership_monthly' : 'membership';

			return this.$t(`page.${termsKey}.disclaimer_checkout`);
		},

		async onInputChange({ productId, amount }) {
			const payload = {
				url: `${process.env.SHOP_API_BASE}/carts/${this.shopCart.id}/products`,
				params: {
					productId: productId,
					quantity: amount
				}
			};

			const addProductResult = await this.$store.dispatch(`shopapi/putRequest`, payload);

			if (!addProductResult || !addProductResult.status || addProductResult.status !== 200) {
				if (addProductResult.data && addProductResult.data.error && addProductResult.data.error.message) {
					this.$store.commit('setModalMessage', addProductResult.data.error.message); // message that gets shown by 8000 error modal
					this.$fireErrorEvent(8000);
				} else {
					console.log('Unknown error response:', addProductResult);
				}
			}
		},

		onRemove(upgradeId) {
			if (!this.isShopUpdatePending) {
				const payload = {
					url: `${process.env.SHOP_API_BASE}/carts/${this.shopCart.id}/products`,
					params: {
						productId: upgradeId,
						quantity: 0
					}
				};

				this.$store
					.dispatch(`shopapi/putRequest`, payload)
					.then(response => {
						if (!response.status || response.status !== 200) {
							if (response.data && response.data.error && response.data.error.message) {
								this.$store.commit('setModalMessage', response.data.error.message); // message that gets shown by 8000 error modal
								this.$fireErrorEvent(8000);
							} else {
								console.log('Unknown error response:', response);
							}
						}
					})
					.catch(error => {
						this.apiErrorMsg =
							error.response &&
							error.response.data &&
							error.response.data.error &&
							error.response.data.error.message;

						if (this.apiErrorMsg) {
							this.$store.commit('setModalMessage', error.response.data.error.message); // message that gets shown by 8000 error modal
							this.$fireErrorEvent(8000);
						} else {
							const formatedError = this.apiErrorMsg
								? `${error.response.data.error.message}, cartId:${this.shopCart.id}`
								: `Probeer het opnieuw. Refresh deze pagina en sluit - indien nodig - aan in de wachtrij om opnieuw in de ticketshop te komen. cartId:${this.shopCart.id}`;
							this.$fireErrorEvent(formatedError);
						}
					});
			}
		}
	}
};
</script>

<style lang="scss">
$component: 'basket-memberships';
</style>
