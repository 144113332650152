<template>
	<div class="wish-total-overview">
		<Grid class="wish-total-overview__ammount" :fullWidth="true">
			<Column :sOffset="0" :mOffset="6" :s="6" :m="4" :noMargin="true">
				<div class="wish-total-overview__ammount-wrapper">
					<span>Total</span>

					<span>{{ wish_list.checkout.total | formatPrice }}</span>
				</div>
			</Column>

			<Column class="wish-total-overview__button-wrapper" :s="6" :m="2" :noMargin="true">
				<Button
					v-if="!shopUpdateState"
					class="wish-total-overview__button button--alternative-second button--small"
					:class="{ 'button--disabled': allSoldOut || buttonsUsed }"
					@click.native="addAll"
				>
					<span>{{ this.$t('wish_list.checkout.add_all') }}</span>
				</Button>

				<Spinner v-else />
			</Column>
		</Grid>
	</div>
</template>

<script>
import Checkbox from '@/components/ui/Checkbox';

export default {
	name: 'WishListTotalOverview',

	data() {
		return {
			buttonsUsed: false
		};
	},

	props: {
		data: {},
		allSoldOut: Boolean
	},

	components: {
		Checkbox
	},

	computed: {
		shopUpdateState() {
			return this.$store.getters['shopapi/getShopUpdateState'];
		},

		wishlist() {
			return this.$store.getters['sso/getWishlist'];
		}
	},

	methods: {
		addAll() {
			this.$emit('addAll');
			this.buttonsUsed = true;
		}
	}
};
</script>

<style lang="scss">
$component: 'wish-total-overview';

.#{$component} {
	position: fixed;
	display: block;
	background: var(--content-background);
	background: #131318;
	width: calc(100% - #{$global-spacing * 4 + 5px}); // 5px scrollbar compensation
	max-width: calc(1180px - 80px);
	height: 100px;
	margin-top: -2px;
	z-index: 10;
	padding: 25px 20px 25px;

	&__ammount {
		text-transform: uppercase;
		font-size: 24px;

		> .grid {
			align-items: center;
		}
	}

	&__button-wrapper {
		flex: 2;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	&__ammount-wrapper {
		text-align: right;
		margin-right: 20px;

		> span {
			display: inline;
		}
	}

	&__terms {
		padding-top: 28px;
	}

	&__text-link {
		text-decoration: none;
		color: rgba(var(--primary-color), 0.8);
	}

	&__cta {
		text-align: right;
	}

	&__button-wrapper {
		text-align: right;
	}

	&__button {
		max-width: 165px;
	}

	@include sm {
		position: relative;

		width: calc(100% - (0px + 5px));

		&__ammount {
			font-size: 18px;
		}

		&__ammount-wrapper {
			text-align: left;
			margin-right: 0;
		}
	}
}
</style>
