<template>
	<div class="basket-confirm" v-if="allowedToRender && hasProductsInBasket">
		<div class="basket-confirm__terms">
			<Checkbox :data="{ value: 'qterms' }" v-model="terms">
				<span v-html="termsAndConditionsMarkup" />
			</Checkbox>
		</div>

		<div v-if="showCovid" class="basket-confirm__terms">
			<Checkbox v-model="covidTerms" :data="{ value: 'covid' }">
				<span
					>I am aware that current or future COVID government restrictions regarding event access (such as an
					entry test or vaccination certificate), do not entitle me to a refund.</span
				>
			</Checkbox>
		</div>
	</div>
</template>

<script>
import Checkbox from '@/components/ui/Checkbox';

export default {
	name: 'BasketTerms',

	data() {
		return {
			terms: null,
			covidTerms: null,
			customError: false,
			checkOutErrors: null,
			allowedToRender: true,
			productAlreadyPurchased: false,
			productName: ''
		};
	},

	components: {
		Checkbox
	},

	computed: {
		brand() {
			return this.$store.getters.getBrand;
		},

		ossConfig() {
			return this.$store.getters.getOssConfig;
		},

		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		productsInBasket() {
			return this.$store.getters['shopapi/getMappedProductsInBasket'];
		},

		hasProductsInBasket() {
			return Object.keys(this.productsInBasket).length > 0;
		},

		isWishlistMode() {
			return this.shopConfig && this.shopConfig.preregistration;
		},

		shopCart() {
			return this.$store.getters['shopconfig/getCart'];
		},
		isMembershipSelected() {
			return this.productsInBasket.hasOwnProperty('membership');
		},

		isTravelOrder() {
			return this.productsInBasket.travel;
		},

		termsAndConditionsMarkup() {
			const isPaylogic = this.shopConfig.shopInit?.paymentProvider !== 'adyen';
			const hasMerchandise = (this.productsInBasket?.merchandise ?? []).length > 0;
			/** @type {boolean} */
			const hasTransport = (this.productsInBasket?.transportation ?? []).some(
				item => item.type?.type === 'bus_travel'
			);
			/** @type {boolean} */
			const hasInsurance = (this.productsInBasket?.insurance ?? []).some(item => item.type?.type === 'insurance');
			/** @type {boolean} */
			const hasTravel = (this.productsInBasket?.travel ?? []).length > 0;
			/** @type {string} */
			const paymentProvider = isPaylogic
				? '<a href="https://www.paylogic.com/en/terms-conditions" target="_blank">Paylogic</a>'
				: '<a href="https://www.justbleep.it/terms" target="_blank">Bleep</a>';
			/** @type {string} */
			let merchandise = '<a href="https://www.id-t.com/merchandise-terms" target="_blank">ID&T Merchandise</a>';
			if (['zwartecross', 'mananamanana'].includes(this.brand)) {
				merchandise =
					'<a href="https://www.feestfabriek.nl/wp-content/uploads/sites/8/2023/05/Algemene-voorwaarden-FF-Merchandise-april-2023.pdf" target="_blank">algemene webshopvoorwaarden van Feestfabriek Merchandise B.V</a>';
			}
			/** @type {string} */
			const transport =
				'<a href="https://www.partybussen.nl/algemene-voorwaarden" target="_blank">Partybussen</a>';
			/** @type {string} */
			const insurance =
				'<a href="https://www.xcover.com/en/pds/booking-refund-protection-eu" target="_blank">XCover</a>';
			/** @type {string} */
			const travel = '<a href="https://dancetravel.nl/terms" target="_blank">Dance Travel</a>';
			/** @type {function(label: string): string} */
			const termsConditions = label =>
				`<a href="${
					this.shopConfig.shopSettings?.fields?.termsConditions ?? '#'
				}" target="_blank">${label}</a>`;
			/** @type {function(label: string): string} */
			const privacyPolicy = label =>
				`<a href="${this.shopConfig.shopSettings?.fields?.privacyPolicy ?? '#'}" target="_blank">${label}</a>`;

			if (this.brand == 'zwartecross') {
				const key = this.isWishlistMode ? 'preregistration' : 'default';
				return this.$t(`checkout.terms.${key}`, {
					termsConditions: termsConditions(
						`algemene voorwaarden voor bezoekers van de ${this.shopConfig.title}`
					),
					privacyPolicy: privacyPolicy('privacyverklaring'),
					paymentProvider,
					merchandise
				});
			} else {
				/** @type {Array.<string>} */
				const terms = [
					`${termsConditions('terms & conditions')} and ${privacyPolicy('privacy policy')} of ${
						this.shopConfig.title
					}`,
					hasMerchandise && merchandise,
					hasTransport && transport,
					hasInsurance && insurance,
					hasTravel && travel
				].filter(Boolean);

				/** @type {string} */
				const allTerms = [terms.slice(0, -1).join(', '), terms.slice(-1)].filter(Boolean).join(' and ');

				return `I accept the ${allTerms}. By accepting, you also agree to the terms & conditions of ${paymentProvider}.`;
			}
		},

		showCovid() {
			// Should be true by default, also if 'showCovidTerms' isn't filled. So only hide if set to 'false' in CTF.
			return this.shopConfig.showCovidTerms !== false;
		}
	},

	watch: {
		terms(val) {
			this.$store.commit('setIsTermsAccepted', val);
		},
		covidTerms(val) {
			this.$store.commit('setIsCovidTermsAccepted', val);
		}
	},

	mounted() {
		this.checkOutErrors = !!this.shopCart.checkoutErrors ? [...this.shopCart.checkoutErrors] : [];
		// this.checkIfProductBoughtEarlier();
	}
};
</script>

<style lang="scss">
$component: 'basket-confirm';

.#{$component} {
	display: block;

	// padding: $global-spacing;
	// background-color: rgba(black, 0.5);

	&__terms {
		font-size: 14px;
		line-height: 1.4;

		input + label {
			font-size: inherit;
			text-transform: none;
		}

		p {
			margin-bottom: 0.5em;
		}
	}

	&__errors {
		padding: $global-spacing;
		margin-bottom: calc($global-spacing / 2);

		color: $color-dark-blue;
		line-height: 1.2;
		font-size: 16px;

		background-color: $color-white;

		p {
			margin-bottom: 6px;

			color: $color-red;
			font-family: $font-bold;
		}

		ul {
			list-style-type: disc;
			padding-left: 1.2em;
		}
	}

	&__cta {
		padding-top: 20px;
	}

	@include sm {
		&__ammount {
			display: flex;
			justify-content: space-between;

			text-transform: capitalize;
			font-size: 18px;

			padding: 0 0 0 $global-spacing--mobile;

			background: none;
		}

		&__terms {
			font-size: 14px;

			label {
				font-size: inherit;
			}
		}
	}
}
</style>
