var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ticket-item",class:{ 'ticket-item--upsell': _vm.data.isUpsell, 'ticket-item--highlight': _vm.data.isHighlight }},[(_vm.data.meta.image)?_c('div',{staticClass:"ticket-item__image",class:{ 'md-lg-only': _vm.data.hideVisualOnMobile }},[_c('img',{attrs:{"src":_vm.cdnUrl(_vm.data.meta.image),"alt":_vm.data.meta.description}})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"ticket-item__content"},[_c('h3',{staticClass:"ticket-item__title"},[_vm._v(_vm._s(_vm.data.name))]),_vm._v(" "),(_vm.showsoldoutWithPrice)?[_c('h3',{staticClass:"ticket-item__price"},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.price)+"\n\t\t\t\t"),(!_vm.hideServiceFee && _vm.showHideServiceFees && _vm.data.meta.calculateTouristTax)?_c('span',{staticClass:"ticket-item__fee"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('page.tickets.includes_fee'))+"\n\t\t\t\t")]):_vm._e(),_vm._v(" "),(!_vm.hideServiceFee && _vm.showHideServiceFees && !_vm.data.meta.calculateTouristTax)?_c('span',{staticClass:"ticket-item__fee"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('page.tickets.includes_fee_tax'))+"\n\t\t\t\t")]):_vm._e()]),_vm._v(" "),(!_vm.showHideServiceFees && _vm.data.meta.calculateTouristTax)?_c('p',{staticClass:"ticket-item__fee 3"},[(!_vm.hideServiceFee)?_c('span',[_vm._v(_vm._s(this.priceBreakdown))]):_vm._e(),_vm._v(" "),(!_vm.hideServiceFee && !_vm.showHideServiceFees)?_c('span',{staticClass:"1"},[_vm._v("\n\t\t\t\t\t+ "+_vm._s(this.formatPrice(this.data.serviceCosts).concat(' ', _vm.$t('checkout.order_fee')))+"\n\t\t\t\t")]):_vm._e(),_vm._v(" "),(!_vm.data.meta.calculateTouristTax)?_c('span',{staticClass:"2"},[_vm._v("\n\t\t\t\t\t+\n\t\t\t\t\t"+_vm._s(this.formatPrice(_vm.data.meta.touristTaxAmount).concat(
							' ',
							this.$t('checkout.tourist_tax.title')
						))+"\n\t\t\t\t")]):_vm._e(),_vm._v(" "),(!_vm.data.meta.mentionEntertainmentFee)?_c('span',{staticClass:"3"},[_vm._v("\n\t\t\t\t\t+\n\t\t\t\t\t"+_vm._s(this.formatPrice(_vm.data.meta.entertainmentFeeAmount).concat(
							' ',
							_vm.$t('checkout.entertainment_tax.title')
						))+"\n\t\t\t\t")]):_vm._e(),_vm._v(" "),(_vm.hideServiceFee && !!_vm.data.meta.additionalTicketText)?_c('span',{staticClass:"4"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.data.meta.additionalTicketText)+"\n\t\t\t\t")]):_vm._e()]):_vm._e()]:_vm._e(),_vm._v(" "),(_vm.data.meta.description)?_c('div',{staticClass:"ticket-item__description",domProps:{"innerHTML":_vm._s(_vm.data.meta.description)}}):_vm._e(),_vm._v(" "),(!_vm.data.meta.overrideSoldout)?[(!_vm.data.soldOut && _vm.data.stockAvailable > 0 && !_vm.data.isUpsell)?_c('div',{staticClass:"ticket-item__item-container",class:{ 'ticket-item__item-container--stepper': _vm.useStepper }},[(_vm.data.meta.perks && _vm.data.meta.perks.length)?_c('div',[_c('div',{staticClass:"ticket-item__includes-wrapper",on:{"click":function($event){return _vm.onToggleusp()}}},[_c('p',{staticClass:"ticket-item__includes"},[_vm._v(_vm._s(_vm.$t('page.tickets.includes')))]),_vm._v(" "),_c('arrow-check',{staticClass:"ticket-item__icon-check-down",class:{ 'ticket-item__icon-check-down--up': _vm.isExpandUsp }})],1),_vm._v(" "),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.isExpandUsp),expression:"isExpandUsp"}],staticClass:"ticket-item__perks"},_vm._l((_vm.data.meta.perks),function(perk,index){return _c('li',{key:index,staticClass:"ticket-item__perk"},[_c('arrow-check',{staticClass:"ticket-item__icon-check"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(perk))])],1)}),0)]):_vm._e(),_vm._v(" "),(!_vm.useStepper)?[_c('SelectFieldSimple',{staticClass:"ticket-item__input",attrs:{"isDisabled":_vm.isShopUpdatePending,"data":_vm.ticketFilterInput},model:{value:(_vm.selectedTickets),callback:function ($$v) {_vm.selectedTickets=$$v},expression:"selectedTickets"}}),_vm._v(" "),(!_vm.isShopUpdatePending)?_c('Button',{staticClass:"button--alternative-second button--small",class:{
							'button--disabled': this.selectedTickets === ''
						},nativeOn:{"click":function($event){return _vm.addTicketsToCart.apply(null, arguments)}}},[_c('span',[_vm._v(_vm._s(_vm.buttonLabel))])]):_c('div',[_c('Spinner')],1),_vm._v(" "),(!_vm.hideTicketMinMax)?_c('p',{staticClass:"ticket-item__max"},[(_vm.data.minQuantity)?_c('span',[_vm._v("Min "+_vm._s(_vm.data.minQuantity)+" p.p. & ")]):_vm._e(),_vm._v("\n\t\t\t\t\t\tMax "+_vm._s(_vm.calculateMaxtickets(_vm.data))+" p.p.\n\t\t\t\t\t")]):_vm._e()]:_vm._e(),_vm._v(" "),(_vm.useStepper)?_c('div',{staticClass:"ticket-item__stepper-container"},[(!_vm.isShopUpdatePending)?_c('InputNumberField',{staticClass:"ticket-item__stepper",attrs:{"isDisabled":_vm.isShopUpdatePending,"stock":_vm.data.stockAvailable,"max-value":_vm.ticketFilterInputStepper,"min-value":_vm.data.minQuantity,"step-size":_vm.data.stepSize,"type":'ticket-stepper',"isSmall":""},model:{value:(_vm.numberOfProducts),callback:function ($$v) {_vm.numberOfProducts=$$v},expression:"numberOfProducts"}}):_c('div',[_c('Spinner')],1),_vm._v(" "),(!_vm.hideTicketMinMax)?_c('p',{staticClass:"ticket-item__max"},[(_vm.data.minQuantity)?_c('span',[_vm._v("Min "+_vm._s(_vm.data.minQuantity)+" p.p. & ")]):_vm._e(),_vm._v("\n\t\t\t\t\t\tMax "+_vm._s(_vm.calculateMaxtickets(_vm.data))+" p.p.\n\t\t\t\t\t")]):_vm._e()],1):_vm._e()],2):((_vm.data.soldOut || _vm.data.stockAvailable < 1) && _vm.isTravelTicketAvailable && !_vm.data.isUpsell)?_c('div',{staticClass:"ticket-item__sold-out"},[_c('Button',{staticClass:"ticket-item__button button--alternative-second button--small",attrs:{"to":{
						name: 'slug-travel',
						params: {
							slug: _vm.$route.params.slug
						}
					}}},[_c('span',[_vm._v(_vm._s(_vm.$t('page.travel.available')))])]),_vm._v(" "),_c('p',{staticClass:"ticket-item__sold-out ticket-item__sold-out--p"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.data.name)+_vm._s(_vm.$t('page.tickets.sold_out'))+"\n\t\t\t\t\t"),_c('nuxt-link',{attrs:{"to":{
							name: 'slug-travel',
							params: {
								slug: _vm.$route.params.slug
							},
							query: _vm.$route.query
						}}},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.$t('page.tickets.sold_out_travel'))+"\n\t\t\t\t\t")])],1)],1):((_vm.data.soldOut || _vm.data.stockAvailable < 1) && !_vm.data.isUpsell)?_c('Button',{staticClass:"button--alternative-second button--small button--disabled"},[_c('span',[_vm._v(_vm._s(_vm.$t('page.tickets.sold_out')))])]):_vm._e()]:[(_vm.data.meta.soldOutTravelCtaText && _vm.data.meta.soldOutText)?[_c('p',{staticClass:"ticket-item__sold-out ticket-item__sold-out--p",domProps:{"innerHTML":_vm._s(_vm.data.meta.soldOutText)}}),_vm._v(" "),_c('Button',{staticClass:"ticket-item__button button--alternative-second button--small",attrs:{"to":{
						name: 'slug-travel',
						params: {
							slug: _vm.$route.params.slug
						}
					}}},[_c('span',[_vm._v(_vm._s(_vm.data.meta.soldOutTravelCtaText))])])]:[_c('p',{staticClass:"ticket-item__sold-out ticket-item__sold-out--p",domProps:{"innerHTML":_vm._s(_vm.data.meta.soldOutText)}})]]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }