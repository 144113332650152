<template>
	<div class="basket">
		<Grid full-width no-margins class="basket__items">
			<Column>
				<BasketUpgrades />
				<BasketMemberships />
				<BasketTickets />
				<BasketTravel />
				<BasketAddons />
				<BasketTransportation />
				<BasketMerchandise />
				<BasketExtras />
				<BasketCompareTransport />
			</Column>
		</Grid>

		<BasketServiceFee />
		<BasketOrderFee />
		<BasketTouristTax />
		<BasketEntertainmentTax />
		<ShippingCostsShopify v-if="hasShopifyMerchandise && !isWishlistMode" />
		<ShippingCostsLightSpeed v-if="hasLightSpeedMerchandise && !isWishlistMode" />
		<RefundProtection
			v-if="showRefund && hasUpgradeModule && !this.shopConfig.previewMode"
			is-upgrade
			:showTitle="hasTitle"
		/>

		<BasketTotal is-subtotal v-if="hasUpgradeInCart && hasMembershipDiscount && discountText" />
		<UpgradeDiscount
			v-if="hasUpgradeInCart && hasMembershipDiscount && discountText"
			class="basket__discount"
			:discountText="discountText"
			:upgradeText="upgradeText"
		/>

		<BasketTotal />

		<Grid class="basket__terms" full-width no-margins v-if="!this.shopConfig.previewMode">
			<Column :m="6">
				<RefundProtection
					v-if="showRefund && !hasUpgradeModule"
					:showTitle="hasTitle"
					:isAddon="showRefund && isAddonShop"
				/>
			</Column>

			<Column :m="6">
				<BasketTerms v-if="!isWishlistMode" />
			</Column>
		</Grid>

		<Grid full-width no-margins>
			<Column :m="6">
				<BasketPreregSave v-if="shopConfig.preregistration" />
				<BasketConfirm v-else-if="!shopConfig.previewMode" />
			</Column>

			<Column>
				<UpgradeUnlock
					v-if="hasUnlockMembershipDiscount && upgradeUnlockText"
					:data="upgradeUnlockText"
					class="basket__unlock"
				/>
			</Column>
		</Grid>

		<UpsellCheckOut />
	</div>
</template>

<script>
import BasketUpgrades from './components/BasketUpgrades';
import BasketMemberships from './components/BasketMemberships';
import BasketTickets from './components/BasketTickets';
import BasketTravel from './components/BasketTravel';
import BasketAddons from './components/BasketAddons';
import BasketTransportation from './components/BasketTransportation';
import BasketMerchandise from './components/BasketMerchandise';
import BasketExtras from './components/BasketExtras';
import BasketCompareTransport from './components/BasketCompareTransport';
import BasketOrderFee from './components/BasketOrderFee';
import BasketServiceFee from './components/BasketServiceFee';
import BasketTouristTax from './components/BasketTouristTax';
import BasketEntertainmentTax from './components/BasketEntertainmentTax';
import ShippingCostsLightSpeed from './components/ShippingCostsLightSpeed';
import ShippingCostsShopify from './components/ShippingCostsShopify';
import BasketTotal from './components/BasketTotal';
import RefundProtection from './components/RefundProtection';
import BasketConfirm from './components/BasketConfirm';
import BasketPreregSave from './components/BasketPreregSave';
import BasketTerms from './components/BasketTerms';
import UpsellCheckOut from './components/UpsellCheckOut';
import UpgradeUnlock from '@/components/upgrade/UpgradeUnlock';
import UpgradeDiscount from '@/components/upgrade/UpgradeDiscount';
import isShopifyProduct from '@/utils/isShopifyProduct';
import isLightSpeedProduct from '@/utils/isLightSpeedProduct';

export default {
	name: 'Basket',

	components: {
		BasketUpgrades,
		BasketMemberships,
		BasketTickets,
		BasketTravel,
		BasketAddons,
		BasketTransportation,
		BasketMerchandise,
		BasketExtras,
		BasketCompareTransport,
		BasketOrderFee,
		BasketServiceFee,
		BasketTouristTax,
		BasketEntertainmentTax,
		ShippingCostsLightSpeed,
		ShippingCostsShopify,
		BasketTotal,
		RefundProtection,
		BasketConfirm,
		BasketPreregSave,
		UpsellCheckOut,
		UpgradeUnlock,
		UpgradeDiscount,
		BasketTerms
	},

	computed: {
		ossConfig() {
			return this.$store.getters.getOssConfig;
		},

		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		productsInBasket() {
			return this.$store.getters['shopapi/getMappedProductsInBasket'];
		},

		hasMerchandise() {
			return !!this.productsInBasket.merchandise;
		},

		hasShopifyMerchandise() {
			if (this.hasMerchandise) {
				return this.productsInBasket.merchandise.some(product => isShopifyProduct(product.type.id));
			}

			return false;
		},

		hasLightSpeedMerchandise() {
			if (this.hasMerchandise) {
				return this.productsInBasket.merchandise.some(product => isLightSpeedProduct(product.type.id));
			}

			return false;
		},

		isWishlistMode() {
			return this.shopConfig && this.shopConfig.preregistration;
		},

		isInsuranceAvailable() {
			return (
				!this.isWishlistMode &&
				this.$store.getters['shopapi/getProducts'].insurance.length &&
				!this.shopConfig.disableRefund
			);
		},

		isInsurableProductInBasket() {
			return this.$store.getters['shopapi/getIsInsurableProductInCart'];
		},

		hasUpgradeModule() {
			return this.$store.getters['shopconfig/getHasUpgradeModule'];
		},

		isUpgradeBookingProtectAvailable() {
			return (
				this.shopConfig.upgradableOrder &&
				this.shopConfig.upgradableOrder.bookingProtect &&
				this.shopConfig.upgradableOrder.bookingProtect.currentOrder
			);
		},
		isAddonShop() {
			return this.shopConfig.shopInit && this.shopConfig.shopInit.isAddOnShop;
		},
		purchasedProductData() {
			return (
				this.$store.getters['shopapi/getPurchasedProducts'] &&
				this.$store.getters['shopapi/getPurchasedProducts'].data.data
			);
		},
		showRefund() {
			if (this.isAddonShop) {
				const allPurchases = this.purchasedProductData['original_orders']
					.map(order => {
						return order.purchases;
					})
					.flat(1);
				const hasBP = allPurchases.filter(purchase => purchase.id === 'insurance').length > 0;
				if (hasBP) {
					if (this.isInsuranceAvailable && this.isInsurableProductInBasket) {
						return this.hasUpgradeModule ? this.isUpgradeBookingProtectAvailable : true;
					}
				} else {
					this.$store.commit('shopapi/setIsRefundChoiceMade', true);
				}
			} else {
				if (this.isInsuranceAvailable && this.isInsurableProductInBasket) {
					return this.hasUpgradeModule ? this.isUpgradeBookingProtectAvailable : true;
				}
			}
		},

		hasTitle() {
			return this.$te('checkout.insurance.title');
		},

		hasUpgradeInCart() {
			const { upgrades } = this.$store.getters['shopapi/getMappedProductsInBasket'];
			return upgrades && upgrades.length;
		},

		upgradableOrder() {
			return this.shopConfig.upgradableOrder;
		},

		hasUnlockMembershipDiscount() {
			return this.upgradableOrder && this.upgradableOrder.unlockMembershipDiscount;
		},

		upgradeUnlockText() {
			return this.upgradableOrder && this.upgradableOrder.checkoutText;
		},

		upgradeText() {
			return this.upgradableOrder && this.upgradableOrder.upgradeText;
		},

		hasMembershipDiscount() {
			return this.upgradableOrder && this.upgradableOrder.hasMembershipDiscount;
		},

		discountText() {
			return this.upgradableOrder && this.upgradableOrder.discount;
		}
	},

	mounted() {
		this.$store.dispatch('dataLayer/viewCart');
	}
};
</script>

<style lang="scss">
$component: 'basket';

.#{$component} {
	display: block;

	margin-top: $checkout-header-height + 2px;
	padding-bottom: $global-spacing;

	&__items {
		margin-bottom: 10px;
	}

	&__warning {
		padding: 16px 20px;
		background-color: $color-red;
	}

	&__terms {
		padding-bottom: $global-spacing;
		margin-bottom: $global-spacing;

		border-bottom: 1px solid var(--primary-body-color);
	}

	&__discount {
		margin-bottom: $global-spacing;
	}

	&__unlock {
		margin-top: 20px;
	}

	@include md-lg {
		&__warning h2 {
			font-size: 30px;
		}
	}
}
</style>
