/**
 * @function isLightSpeedProduct
 * @description Returns true if the given string starts with the string 'lightspeed_'.
 * @param {string} str - The string to check.
 * @returns {boolean} Whether the string starts with 'shopify_'.
 */
export function isLightSpeedProduct(str) {
	return typeof str === 'string' && str.startsWith('lightspeed_');
}

export default isLightSpeedProduct;
