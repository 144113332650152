<template>
	<Grid v-if="entertainmentTax" class="entertainment-tax" full-width no-margins>
		<Column :s="6">
			<span class="entertainment-tax__span">
				{{ $t('checkout.entertainment_tax.title') }}
				<div class="entertainment-tax__tooltip-marker"></div>
				<div class="entertainment-tax__tooltip">
					{{ $t('checkout.entertainment_tax.lead') }}
				</div>
			</span>
		</Column>

		<Column :s="6">
			<h3 class="entertainment-tax__price">{{ entertainmentTax | formatPrice }}</h3>
		</Column>
	</Grid>
</template>

<script>
export default {
	name: 'BasketEntertainmentTax',
	computed: {
		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		productsInBasket() {
			return this.$store.getters['shopapi/getMappedProductsInBasket'];
		},

		ossConfig() {
			return this.$store.getters.getOssConfig;
		},

		entertainmentTax() {
			let tax = 0;

			if (this.productsInBasket.ticket) {
				this.productsInBasket.ticket.forEach(singleTicket => {
					// console.log(singleTicket.type.meta)
					if (
						singleTicket.type.meta.entertainmentFeeAmount !== 0 &&
						singleTicket.type.meta.mentionEntertainmentFee
					) {
						tax += singleTicket.type.meta.entertainmentFeeAmount * singleTicket.numproducts;
					}
				});
			}

			// console.log(this.productsInBasket, tax,'tourist')

			return tax;
		}
	}
};
</script>

<style lang="scss">
$component: 'entertainment-tax';

.#{$component} {
	padding-bottom: 5px;

	&__price {
		text-align: right;
	}
	&__span {
		position: relative;
		display: inline-block;
	}
	&__tooltip-marker {
		position: absolute;
		bottom: 0.22em;
		right: -1.75em;
		height: 1.25em;
		width: 1.25em;
		border: 1px solid white;
		border-radius: 50%;
		background-color: transparent;
		font-size: 1em;

		&:after {
			content: 'i';
			position: absolute;
			font-size: 12px;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	&__tooltip {
		opacity: 0;
		visibility: hidden;
		font-size: 12px;
		text-align: center;
		position: absolute;
		width: 18rem;
		right: 0;
		top: -2rem;
		transform: translate(18rem, 1rem);
		background-color: white;
		// border: 1px solid #5e5e66;
		padding: 0.75rem;
		z-index: 99;
		transition: 0.3s ease all;
		color: black;
		span {
			cursor: pointer;
			text-decoration: underline;
		}
		@include sm {
			left: 1rem;
			right: auto;
			transform: translate(1rem, 0);
		}
	}
	&__tooltip-marker:hover + &__tooltip,
	&__tooltip:hover {
		transform: translate(18rem, 0);
		visibility: visible;
		pointer-events: all;
		opacity: 1;
		@include sm {
			transform: translate(1rem, 0);
		}
	}
}
</style>
