<template>
	<div class="shipping">
		<Grid full-width no-margins>
			<Column :m="6">
				<div class="shipping-shopify__explainer" v-html="$t('checkout.shipping_costs.explainer')" />

				<SelectField
					class="shipping__select"
					ref="countrySelect"
					:label="$t('checkout.shipping_costs.label')"
					:name="'country'"
					:valueFromDataAttr="false"
					:preselected="getPrefilledData"
					v-model="country"
				>
					<option
						v-for="option in filterdCountryList"
						:key="option.countrycode + option.price"
						:value="option.countryname"
						:name="option.countryname"
						v-bind:data-id="option.price"
					>
						{{ option.countryname }}
					</option>
				</SelectField>
			</Column>
		</Grid>

		<transition name="fade">
			<Grid v-if="shippingMessage" full-width no-margins>
				<Column :s="6">
					<!--<span class="shipping__price" v-html="shippingMessage"></span>-->
				</Column>

				<Column :s="6">
					<h3 class="shipping__price">{{ shippingPriceFormatted | formatPrice }}</h3>
				</Column>
			</Grid>
		</transition>
	</div>
</template>

<script>
import CountryList from '@/static/data/shippingCosts.json';
import SelectField from '@/components/ui/SelectField';

export default {
	name: 'ShippingCostsLightSpeed',

	data() {
		return {
			country: null,
			countryList: CountryList
		};
	},

	components: {
		SelectField
	},

	computed: {
		brand() {
			return this.$store.getters.getBrand;
		},

		filterdCountryList() {
			if (['zwartecross', 'mananamanana'].includes(this.brand)) {
				const allowed = [
					{ countryname: 'Netherlands', newType: 'Nederland' },
					{ countryname: 'Belgium', newType: 'België' },
					{ countryname: 'Germany', newType: 'Duitsland' }
				];
				const countries = this.countryList.filter(item => {
					const found = allowed.some(country => {
						if (country.countryname === item.countryname) {
							return true;
						}
						return false;
					});
					if (found) {
						return item;
					}
				});
				const updatedCountries = countries.map(item => {
					const correspondingCountry = allowed.find(country => country.countryname === item.countryname);
					if (correspondingCountry) {
						return { ...item, countryname: correspondingCountry.newType };
					}
					return item;
				});
				updatedCountries.push({
					countrycode: 'na',
					countryname: 'Andere landen',
					deliverytype: 'shipping',
					price: 0
				});
				return updatedCountries;
			}
			return this.countryList;
		},

		shippingMessage() {
			const shippingError = this.$t('error.shipping_unavailable');

			if (this.country) {
				if (this.shippingPrice > 0) {
					return 'Shipping cost:';
				} else {
					return shippingError;
				}
			}

			return '';
		},

		shippingPrice() {
			return this.$store.getters['shopapi/getShippingPrice'];
		},

		shippingPriceFormatted() {
			if (this.country) {
				return this.shippingPrice > 0 ? this.shippingPrice : '';
			}

			return '';
		},

		productsInBasket() {
			return this.$store.getters['shopapi/getMappedProductsInBasket'];
		},

		merchandiseInBasket() {
			return this.productsInBasket && this.productsInBasket.merchandise;
		},

		getPrefilledData() {
			return this.$store.getters['shopapi/getShippingCountry'];
		},

		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		shopCart() {
			return this.$store.getters['shopconfig/getCart'];
		}
	},

	watch: {
		country(val) {
			if (val) {
				let countryPrice = this.countryList.find(item => item.countryname === val.value);
				let selectedCountry = val.value;
				if (this.brand === 'zwartecross' || this.brand === 'mananamanana') {
					const allowedCountries = ['Duitsland', 'België', 'Nederland'].includes(val.value);
					if (allowedCountries) {
						const allowed = [
							{ countryname: 'Netherlands', newType: 'Nederland' },
							{ countryname: 'Belgium', newType: 'België' },
							{ countryname: 'Germany', newType: 'Duitsland' }
						];
						allowed.find(item => {
							if (item.newType === val.value) {
								const tmpcountryPrice = this.countryList.find(
									country => country.countryname === item.countryname
								);
								countryPrice = tmpcountryPrice;
								selectedCountry = item.countryname;
								return;
							}
						});
					}
				}

				if (countryPrice && countryPrice.price > 0) {
					this.$store.commit('shopapi/setShippingPrice', countryPrice.price);
					this.$store.commit('shopapi/setShippingCountry', selectedCountry);
				} else if (this.merchandiseInBasket && this.merchandiseInBasket.length) {
					this.$nextTick(() => {
						// Price <= 0? Than merchandise is not deleverable. Remove merchandise from cart.

						//zwartecorss has differtn logic for shopping methods
						if (this.brand === 'zwartecross' || this.brand === 'mananamanana') {
							this.$store.commit('shopapi/setShippingPrice', 0);
							this.$store.commit('shopapi/setShippingCountry', '');
							this.country = null;
							this.$fireErrorEventWithPromis(6007)
								.then(response => {
									// if yes remove from basket
									this.country = null;
									this.$refs.countrySelect.setDefaults();
									this.$store.commit('shopapi/setShippingPrice', 0);
									this.$store.commit('shopapi/setShippingCountry', '');

									this.$store.dispatch(
										'shopapi/removeOptionFromExperienceBuilder',
										this.merchandiseInBasket
									);
								})
								.catch(error => {
									//do nothing
								});
						} else {
							this.country = null;
							this.$refs.countrySelect.setDefaults();
							this.$store.commit('shopapi/setShippingPrice', 0);
							this.$store.commit('shopapi/setShippingCountry', '');

							this.$store.dispatch('shopapi/removeOptionFromExperienceBuilder', this.merchandiseInBasket);

							this.$fireErrorEvent(5003);
						}
					});
				}
			}
		}
	}
};
</script>

<style lang="scss">
$component: 'shipping';

.#{$component} {
	display: block;
	margin-bottom: $global-spacing;

	// background: var(--content-background);

	/* &__title {
		font-family: $font-regular;
		font-size: 20px;
		text-transform: uppercase;

		padding-bottom: 5px;
	}

	&__sub-title {
		display: block;
		padding-bottom: 20px;
	} */

	&__price {
		text-align: right;
	}

	&__disclaimer {
		font-size: 14px;
	}

	&__body {
		font-size: 14px;
	}

	@include sm {
		&__select {
			margin-bottom: 2px;
		}
		/* &__content {
			padding-bottom: 35px;
		} */
	}
}
</style>
