<template>
	<div class="shipping-shopify">
		<Grid full-width no-margins>
			<Column :m="6">
				<div class="shipping-shopify__explainer" v-html="$t('checkout.shipping_costs.explainer')"></div>
				<div v-if="!showLoader">
					<SelectField
						class="shipping__select"
						ref="countrySelect"
						:label="$t('checkout.shipping_costs.label')"
						:name="'shipping-country'"
						:valueFromDataAttr="false"
						:preselected="getPrefilledData"
						v-model="country"
					>
						<option
							v-for="country in filterdCountryList"
							:key="country.type"
							:value="country.type"
							:name="country.type"
							v-bind:data-id="country.type"
						>
							{{ country.type }}
						</option>
					</SelectField>
					<div class="shipping-shopify__options">
						<RadioButton
							v-for="option in options"
							:key="option.value"
							class="shipping-shopify__radio"
							:data="{
								selectedValue: false,
								label: option.label,
								value: option.value,
								name: 'shipping-option'
							}"
							@input="onSelect"
						/>
					</div>
				</div>
				<Spinner v-else />
			</Column>
		</Grid>

		<transition name="fade">
			<Grid v-if="shippingMessage" full-width no-margins>
				<Column :s="6" />
				<Column :s="6">
					<h3 class="shipping__price">{{ shippingPriceFormatted | formatPrice }}</h3>
				</Column>
			</Grid>
		</transition>
	</div>
</template>

<script>
import CountryList from '@/static/data/countryCodes.json';
import SelectField from '@/components/ui/SelectField';
import RadioButton from '@/components/ui/RadioButton.vue';
import Spinner from '@/components/ui/Spinner.vue';

export default {
	name: 'ShippingCostsShopify',

	data() {
		return {
			country: null,
			showLoader: false,
			options: []
		};
	},

	components: {
		SelectField,
		RadioButton,
		Spinner
	},

	computed: {
		brand() {
			return this.$store.getters.getBrand;
		},

		filterdCountryList() {
			const popularCountries = ['NL', 'DE', 'BE'];
			const filteredCountryList = CountryList.reduce((acc, country) => {
				if (popularCountries.includes(country.value.toUpperCase())) {
					return [country, ...acc];
				}
				return [...acc, country];
			}, []);

			return filteredCountryList;
		},

		shippingMessage() {
			const shippingError = this.$t('error.shipping_unavailable');

			if (this.country) {
				if (this.shippingPrice > 0) {
					return 'Shipping cost:';
				} else {
					return shippingError;
				}
			}

			return '';
		},

		shippingPrice() {
			return this.$store.getters['shopapi/getShippingPrice'];
		},

		shippingPriceFormatted() {
			if (this.country) {
				return this.shippingPrice > 0 ? this.shippingPrice : '';
			}

			return '';
		},

		productsInBasket() {
			return this.$store.getters['shopapi/getMappedProductsInBasket'];
		},

		merchandiseInBasket() {
			return this.productsInBasket && this.productsInBasket.merchandise;
		},

		getPrefilledData() {
			return this.$store.getters['shopapi/getShippingCountry'];
		},

		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		shopCart() {
			return this.$store.getters['shopconfig/getCart'];
		}
	},

	methods: {
		getCountryCodeFromName(name) {
			const search = this.filterdCountryList.find(country => country.type === name);
			if (search) {
				return search.value.toUpperCase();
			}

			return '';
		},

		onSelect(value) {
			const parsedValue = value.split('|');
			if (parsedValue.length > 1) {
				this.$store.commit('shopapi/setShippingPrice', parsedValue[1]);
				this.$store.commit('shopapi/setShippingHandle', parsedValue[0]);
			} else {
				this.$store.commit('shopapi/setShippingPrice', 0);
				this.$store.commit('shopapi/setShippingHandle', null);
			}
		}
	},

	watch: {
		async country(val, oldVal) {
			if (val && val.value !== oldVal?.value) {
				const countryCode = this.getCountryCodeFromName(val.value);
				if (!countryCode) {
					console.warn('Country code not found for', val.value);
					return;
				}

				this.showLoader = true;

				const payload = {
					url: `/carts/${this.shopCart.id}/shipping`,
					params: {
						countryCode
					}
				};

				try {
					const fetchShippingOptions = await this.$store.dispatch(
						'shopapi/fetchShopifyShippingOptions',
						payload
					);

					if (fetchShippingOptions) {
						const {
							data: {
								data: { shippingMethods }
							}
						} = fetchShippingOptions;

						if (Array.isArray(shippingMethods) && shippingMethods.length > 0) {
							this.options = [];
							shippingMethods.forEach(method => {
								this.options.push({
									label: `${method.name} - €${method.price}`,
									value: `${method.handle} | ${method.price}`
								});
							});
						} else {
							throw new Error('No shipping methods found');
						}
					}

					this.$store.commit('shopapi/setShippingCountry', val.value);
				} catch (error) {
					console.error('error', error);
					this.country = null;
					// this.$refs.countrySelect.setDefaults();
					this.$store.commit('shopapi/setShippingPrice', 0);
					this.$store.commit('shopapi/setShippingCountry', '');
					this.$fireErrorEvent(5003);
					this.$store.dispatch('shopapi/removeOptionFromExperienceBuilder', this.merchandiseInBasket);
				}

				this.showLoader = false;
			}
		}
	}
};
</script>

<style lang="scss">
$component: 'shipping-shopify';

.#{$component} {
	display: block;
	margin-bottom: $global-spacing;

	&__explainer {
		display: block;
		font-size: 12px;
		padding-bottom: 12px;
	}

	&__price {
		text-align: right;
	}

	&__disclaimer {
		font-size: 14px;
	}

	&__body {
		font-size: 14px;
	}

	&__options {
		margin: 1rem 0;
	}

	@include sm {
		&__select {
			margin-bottom: 2px;
		}
	}
}
</style>
