<template>
	<div class="basket-confirm" v-if="allowedToRender && hasProductsInBasket">
		<div v-if="!isCheckoutAllowed || customError" class="basket-confirm__errors">
			<p>{{ $t('alert.no_checkout') }}</p>

			<ul>
				<li v-for="(error, index) in checkOutErrors" :key="`error${index}`">
					<span v-html="error.message"></span>
				</li>
			</ul>
		</div>

		<Button
			class="button--alternative-second"
			v-if="!shopConfig.preregistration"
			@click.native="gotoPersonalDetail"
			:is-disabled="!isCheckoutAllowed"
		>
			<span>{{ $t('label.cart.order') }}</span>
		</Button>

		<Button class="button--alternative-second" v-else>
			<span>{{ $t('label.cart.save') }}</span>
		</Button>
	</div>
</template>

<script>
export default {
	name: 'BasketConfirm',

	data() {
		return {
			customError: false,
			checkOutErrors: null,
			allowedToRender: true,
			// productAlreadyPurchased: false,
			productName: ''
		};
	},

	computed: {
		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		productsInBasket() {
			return this.$store.getters['shopapi/getMappedProductsInBasket'];
		},

		hasProductsInBasket() {
			return Object.keys(this.productsInBasket).length > 0;
		},

		isInsurableProductInBasket() {
			return this.$store.getters['shopapi/getIsInsurableProductInCart'];
		},

		isRefundProtectionAvailable() {
			const isInsuranceAvailable =
				this.$store.getters['shopapi/getProducts'].insurance.length && !this.shopConfig.disableRefund;

			return isInsuranceAvailable && this.isInsurableProductInBasket;
		},

		shopCart() {
			return this.$store.getters['shopconfig/getCart'];
		},

		isCheckoutAllowed() {
			return this.shopCart && this.shopCart.checkoutAllowed;
		},

		hasShippingCountry() {
			return !!this.$store.getters['shopapi/getShippingCountry'];
		},

		isRefundChoiceMade() {
			return this.$store.getters['shopapi/getIsRefundChoiceMade'];
		},

		isTermsAccepted() {
			return this.$store.getters.getIsTermsAccepted;
		},

		isCovidTermsAccepted() {
			return this.$store.getters.getIsCovidTermsAccepted;
		},

		hasTravelButNoTickets() {
			const hasTravel = this.productsInBasket.hasOwnProperty('travel');
			const hasTicket = this.productsInBasket.hasOwnProperty('ticket');

			if (hasTravel) {
				return !hasTicket ? true : false;
			}

			return false;
		},

		hasUpgradeModule() {
			return this.$store.getters['shopconfig/getHasUpgradeModule'];
		},

		isUpgradeBookingProtectAvailable() {
			return (
				this.shopConfig.upgradableOrder &&
				this.shopConfig.upgradableOrder.bookingProtect &&
				this.shopConfig.upgradableOrder.bookingProtect.currentOrder
			);
		},

		isAddonShop() {
			return this.shopConfig.shopInit && this.shopConfig.shopInit.isAddOnShop;
		}
	},

	methods: {
		gotoPersonalDetail() {
			if (this.hasTravelButNoTickets) {
				if (!this.isAddonShop) {
					this.$fireErrorEventWithPromis(6003).then(response => {
						this.checkAllTerms() &&
							this.isCheckoutAllowed &&
							this.$store.commit('setShowCheckoutForm', true);
					});
					return;
				}
			}

			if (this.checkAllTerms() && this.isCheckoutAllowed) {
				this.$store.commit('setShowCheckoutForm', true);
			}
		},

		checkAllTerms() {
			let allOk = true;
			this.customError = false;
			this.checkOutErrors = [...this.shopCart.checkoutErrors];

			if (
				this.hasShippingCountry &&
				this.$route.params.slug === '2020-defqon1-at-home' &&
				this.$store.getters['shopapi/getShippingPrice'] === 0
			) {
				this.checkOutErrors.push({
					code: 'custom.error',
					message: $t('error.shipping_unavailable')
				});
				this.customError = true;
				allOk = false;
			}

			if (!this.isTermsAccepted) {
				this.checkOutErrors.push({
					code: 'custom.error',
					message: this.$t('error.form_terms_not_accepted')
				});
				this.customError = true;
				allOk = false;
			}

			if (this.shopConfig.showCovidTerms !== false && !this.isCovidTermsAccepted) {
				this.checkOutErrors.push({
					code: 'custom.error',
					message: this.$t('error.form_covid_not_accepted')
				});
				this.customError = true;
				allOk = false;
			}

			if (this.productsInBasket.merchandise && !this.hasShippingCountry) {
				this.checkOutErrors.push({
					code: 'custom.error',
					message: this.$t('error.form_no_country_selected')
				});
				this.customError = true;
				allOk = false;
			}

			if (this.isRefundProtectionAvailable && !this.isRefundChoiceMade) {
				if (!this.hasUpgradeModule || (this.hasUpgradeModule && this.isUpgradeBookingProtectAvailable)) {
					this.checkOutErrors.push({
						code: 'custom.error',
						message: this.$t('error.form_no_insurance_choice')
					});
					this.customError = true;
					allOk = false;
				}
			}

			/* if (this.productAlreadyPurchased) {
				this.checkOutErrors.push({
					code: 'custom.error',
					message: `<strong>You already have access to the ${this.productName}.</strong> <br/><br/>Quiz tickets are bound to your account and can only be purchased once.`
				});
				this.customError = true;
				allOk = false;
			} */

			return allOk;
		}

		/* checkIfProductBoughtEarlier() {
			if (this.productsInBasket.hasOwnProperty('ticket')) {
				this.productAlreadyPurchased = false;

				this.productsInBasket.ticket.forEach(item => {
					const url = 'https://o7azvl6gyd.execute-api.eu-west-1.amazonaws.com/prod/api/check';
					const params = {
						vendor: 'paylogic',
						vendor_id: item.type.id.split('_')[1]
					};
					const config = {
						headers: {
							'X-Event-Namespace': 'qdance_defqon1_2020_athome_85ZpJgKl',
							'X-User-Token': controller.getAccessTokens().idToken
						},
						params
					};

					this.$axios
						.get(url, config)
						.then(response => {
							this.allowedToRender = true;
							if (response.data.data) {
								this.productAlreadyPurchased = true;
								this.productName = item.type.name;
							}
						})
						.catch(error => {
							this.allowedToRender = true;
							this.productAlreadyPurchased = true;
						});
				});
			} else {
				this.allowedToRender = true;
			}
		} */
	},

	mounted() {
		this.checkOutErrors = !!this.shopCart.checkoutErrors ? [...this.shopCart.checkoutErrors] : [];
		// this.checkIfProductBoughtEarlier();
	}
};
</script>

<style lang="scss">
$component: 'basket-confirm';

.#{$component} {
	display: block;

	// padding: $global-spacing;
	// background-color: rgba(black, 0.5);

	/* &__terms {
		font-size: 14px;
		line-height: 1.4;

		input + label {
			font-size: inherit;
			text-transform: none;
		}

		p {
			margin-bottom: 0.5em;
		}
	} */

	&__errors {
		padding: $global-spacing;
		margin-bottom: calc($global-spacing / 2);

		color: $color-dark-blue;
		line-height: 1.2;
		font-size: 16px;

		background-color: $color-white;

		p {
			margin-bottom: 6px;

			color: $color-red;
			font-family: $font-bold;
		}

		ul {
			list-style-type: disc;
			padding-left: 1.2em;
		}
	}

	&__cta {
		padding-top: 20px;
	}

	@include sm {
		&__ammount {
			display: flex;
			justify-content: space-between;

			text-transform: capitalize;
			font-size: 18px;

			padding: 0 0 0 $global-spacing--mobile;

			background: none;
		}

		/* &__terms {
			font-size: 14px;

			label {
				font-size: inherit;
			}
		} */
	}
}
</style>
